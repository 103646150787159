import TagManager from '@sooro-io/react-gtm-module';
import { isDevelopment } from 'web/utils/constants';

const gtmContainerId = 'GTM-N38KLNLJ';

if (!isDevelopment) {
  TagManager.initialize({
    gtmId: gtmContainerId,
  });
}
