// =================================================================
// === THIS IS AN AUTO-GENERATED FILE
// === DO NOT EDIT THIS FILE DIRECTLY
// === Edit its source file in javascript/erb/source and then
// === run `rails rails_constants:render` to regenerate this file
// =================================================================

// AUTOGENERATED!
const CIRCLE_VISIBILITY_TYPES = {
  OPEN: 'open',
  PUBLIC: 'public',
  EXPERT: 'expert',
  SOLO: 'solo',
  PRIVATE: 'private',
};

// AUTOGENERATED!
export default {
  CIRCLE_VISIBILITY_TYPES,
  assets: {},
  cypress: {
    BRANCH_API_KEY: '',
    SENTRY_DSN: null,
  },
  development: {
    BRANCH_API_KEY: 'key_test_iAoAHRANFDHQka2p7AXoDibjECpoF08k',
    SENTRY_DSN:
      'https://1854a3bf5358e7b7d3a85f476fbbd98e@o4505512652505088.ingest.sentry.io/4506066736381952',
  },
  production: {
    BRANCH_API_KEY: 'key_live_nwfFGTDUDwNHgm3jYF9lBpcgBDolB49x',
    SENTRY_DSN:
      'https://1854a3bf5358e7b7d3a85f476fbbd98e@o4505512652505088.ingest.sentry.io/4506066736381952',
  },
  staging: {
    BRANCH_API_KEY: 'key_test_iAoAHRANFDHQka2p7AXoDibjECpoF08k',
    SENTRY_DSN:
      'https://1854a3bf5358e7b7d3a85f476fbbd98e@o4505512652505088.ingest.sentry.io/4506066736381952',
  },
  test: {
    BRANCH_API_KEY: '',
    SENTRY_DSN: null,
  },
};

// AUTOGENERATED!
export const routingConstants = {
  circles: {
    base: 'circles',
    read: 'read',
  },
  documents: {
    base: 'documents',
    tabs: {
      all: 'all',
      chat: 'chat',
      threads: 'threads',
      circles: 'circles',
    },
  },
  public: {
    terms: 'terms',
  },
  settings: {
    accountSettings: {
      base: 'account-settings',
      manage: 'manage-account',
      delete: 'delete-account',
    },
  },
  userProfile: {
    base: 'user-profile',
    circles: 'circles',
    reads: 'reads',
  },
  search: {
    base: 'search',
    results: {
      base: 'results',
      books: 'books',
      circles: 'circles',
      users: 'users',
    },
  },
} as const;

// AUTOGENERATED!
