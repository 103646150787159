import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { RootState } from '..';
import { useSelector } from 'react-redux';

interface FirebaseSlice {
  firebaseUser: firebase.User | null;
}

export const initialState: FirebaseSlice = {
  firebaseUser: null,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    firebaseUser: null as firebase.User | null,
  },
  reducers: {
    setFirebaseUser: (state: FirebaseSlice, action: PayloadAction<firebase.User>) => {
      state.firebaseUser = action.payload;
    },
  },
});

export const useFirebaseUserState = (): FirebaseSlice =>
  useSelector((state: RootState) => state.firebase);

// Action creators are generated for each case reducer function
export const { setFirebaseUser } = settingsSlice.actions;

export default settingsSlice.reducer;
