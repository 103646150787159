// ensures that the classes object conforms to the classNames API,
// turns the label (key) into a class name itself,
// ensuring that elements are labeled and readable in the DOM
//
// example:
// import { getLcn } from 'labeled-classnames';
//
// const lcn = getLcn({
//    'exampleContainer': 'mb-3'
// });
//
// In React:
// <p className={lcn.exampleContainer}>hi</p>
//
// Yields in DOM:
// <p class='example-container mb-3'>hi</p>

import classNames from 'classnames';
import { dasherize, underscore } from 'inflection';

export type LcnObject<Classes> = {
  [Key in keyof Classes]: string;
};

export const labelToClass = (label: string) => dasherize(underscore(label));

export function getLcn<Classes extends Record<string, classNames.Argument>>(
  classes: Classes,
): LcnObject<Classes> {
  const lcn = {} as LcnObject<Classes>;

  Object.keys(classes).forEach((key: keyof Classes & string) => {
    const domClassLabel = labelToClass(key);
    const cn = classNames(classes[key]);
    lcn[key] = domClassLabel + ' ' + cn;
  });

  return lcn;
}
