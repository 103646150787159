import { pl } from 'date-fns/locale';
import { pluralize as inflectionPluralize } from 'inflection';
import { User } from 'web/store/users/types';
import { Empty } from './types';

// @ts-expect-error - Cypress is defined when running Cypress tests
export const IS_E2E_TEST = !!window.Cypress;

export const hasExtension = function (filename: string, ext: string) {
  return filename.split('.').reverse()[0] === ext;
};

// TODO: wraps inflection library to allow passing of number
export const pluralize = function (count: number | Empty, singular: string, plural?: string) {
  if (count === 1) return singular;

  return inflectionPluralize(singular, plural);
};

/**
 * Return true if the user is a leader of the circle
 * @param circleLeaders An array of user objects representing the circle leaders
 * @param userId A string representing a user's id
 * @returns boolean
 */
export const getUserIsLeader = (circleLeaders: Array<User>, userId: string) => {
  if (!circleLeaders) {
    return false;
  }
  return circleLeaders.some(leader => leader.id === userId);
};

/**
 * Return 'You' if the user is the session user, otherwise return the user's display name
 * @param sessionUserId  A string representing the session user's id
 * @param user  A user object to compare to the sessionUser
 * @returns string
 */
export const getUserDisplayName = (sessionUserId: string | undefined, user: User | undefined) => {
  if (!user) {
    return '';
  }
  return sessionUserId === user.id ? 'You' : user.display_name ?? '';
};

/**
 * Get the root element of the app
 *
 * @returns HTMLElement
 */
export const getAppRootElement = () => {
  return document.getElementById('app-container');
};

export const getChangedFormValues = <K extends Record<string, unknown>>(
  data: K,
  dirtyFields: Partial<Readonly<Record<keyof K, unknown>>>,
) => {
  const changedValues = Object.keys(dirtyFields).reduce((acc, key: keyof K) => {
    acc[key] = data[key];
    return acc;
  }, {} as Partial<K>);
  return changedValues;
};
