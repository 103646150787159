import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { BookCircle } from './types';
import { useSelector } from 'react-redux';
import { RootState } from '..';

interface BookCircleSliceState {
  currentBookCircle: BookCircle | null;
}

const initialState: BookCircleSliceState = {
  currentBookCircle: null,
};

export const bookCircleSlice = createSlice({
  name: 'bookCircles',
  initialState,
  reducers: {
    setCurrentBookCircle: (state, action: PayloadAction<BookCircle>) => {
      state.currentBookCircle = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCurrentBookCircle } = bookCircleSlice.actions;

/**
 * Custom hook to access the book circle slice of the Redux store.
 */
export const useBookCircleState = (): BookCircleSliceState =>
  useSelector((state: RootState) => state.bookCircles);

export default bookCircleSlice.reducer;
