import { useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';

import { signInWithToken } from 'web/api/session';
import { IS_E2E_TEST } from './general';
import { ValueOf } from './types';
import { showToast } from './toast';
import { withAsyncErrorHandling } from 'web/api';
import displayFirebaseError from './auth/displayFirebaseError';
import { reactQueryClient } from 'web/App';
import { queryKeys } from 'web/api/queryKeys';
import { useDispatch } from 'react-redux';
import { setFirebaseUser } from 'web/store/firebase/slice';
import useLoginUserFromIdToken from './useLoginUserFromIdToken';
import * as GA from 'web/utils/analytics/google-analytics';

export enum AuthMethod {
  GOOGLE = 'google',
  APPLE = 'apple',
  SIGN_IN_EMAIL_AND_PASSWORD = 'sign-in-email-and-password',
  SIGN_UP_EMAIL_AND_PASSWORD = 'sign-up-email-and-password',
}

// Only used for e2e tests
const testFbConfig = {
  apiKey: '12345',
};

const signInWithGoogle = async () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  firebase.auth().signInWithPopup(provider);
};

const signInWithApple = async () => {
  const provider = new firebase.auth.OAuthProvider('apple.com');
  firebase.auth().signInWithPopup(provider);
};

export const sendEmailVerification = async (firebaseUser: firebase.User) => {
  try {
    await firebaseUser.sendEmailVerification();
    showToast({ message: 'Verification email sent.', type: 'success' });
  } catch (err) {
    displayFirebaseError(err);
  }
};

export type ExistingUserLoginData = {
  email: string;
  password: string;
};

export type NewUserLoginData = ExistingUserLoginData & {
  displayName: string;
};

const signInWithEmailAndPassword = async (data: ExistingUserLoginData) => {
  // check if email already exists
  try {
    await firebase.auth().signInWithEmailAndPassword(data.email, data.password);
  } catch (err) {
    console.log(err);
    displayFirebaseError(err);
  }
};

// upon sign in, attempt to determine if it is a sign up
const isSignUp = (firebaseUser: firebase.User) => {
  const creationTime = firebaseUser.metadata.creationTime;
  const lastSignInTime = firebaseUser.metadata.lastSignInTime;

  if (!creationTime || !lastSignInTime) return;

  const creationTimeStamp = new Date(creationTime).getTime();
  const lastSignInTimeStamp = new Date(lastSignInTime).getTime();

  // is the signin close to the user creation time?
  // this may not be 100% accurate, but it's a tolerable heuristic
  return Math.abs(lastSignInTimeStamp - creationTimeStamp) < 2000;
};

const signUpWithEmailAndPassword = async (data: NewUserLoginData) => {
  try {
    const credentials = await firebase
      .auth()
      .createUserWithEmailAndPassword(data.email, data.password); // automatically signs in

    if (credentials.user) {
      await credentials.user.updateProfile({ displayName: data.displayName });
      await credentials.user.sendEmailVerification();
    } else {
      return false;
    }
    return true;
  } catch (err) {
    displayFirebaseError(err);
    return false;
  }
};

export type SignInArgs = {
  authMethod: ValueOf<AuthMethod>;
  data?: ExistingUserLoginData | NewUserLoginData;
};

export const signIn = async ({ authMethod, data }: SignInArgs) => {
  switch (authMethod) {
    case AuthMethod.GOOGLE:
      return signInWithGoogle();
    case AuthMethod.APPLE:
      return signInWithApple();
    case AuthMethod.SIGN_IN_EMAIL_AND_PASSWORD:
      return signInWithEmailAndPassword(data as ExistingUserLoginData);
    case AuthMethod.SIGN_UP_EMAIL_AND_PASSWORD:
      return signUpWithEmailAndPassword(data as NewUserLoginData);
    default:
      throw new Error(`Unknown authMethod: ${authMethod}`);
  }
};

export const signOut = async () => firebase.auth().signOut();

export async function getToken(forceRefresh = false) {
  const idTokenResult = await firebase.auth().currentUser?.getIdTokenResult(forceRefresh);
  return idTokenResult?.token;
}

export const showMustVerifyEmailToast = (firebaseUser: firebase.User) => {
  showToast({
    message: 'Please check your email and click the verify link before continuing.',
    cta: {
      text: 'Resend verification email',
      onClick: () => sendEmailVerification(firebaseUser),
    },
    type: 'info',
  });
};

export default function useFirebase(firebaseConfig: object) {
  const dispatch = useDispatch();
  const { loginUserFromIdToken } = useLoginUserFromIdToken();

  const setUserInStore = async () => {
    const token = await getToken();
    if (token) {
      withAsyncErrorHandling(async () => {
        const user = await signInWithToken(token);
        reactQueryClient.setQueryData([queryKeys.session], user);
      });
    }
  };

  useEffect(() => {
    // Use emulator and test config if running Cypress tests
    const shouldUseEmulator = IS_E2E_TEST;
    const config = shouldUseEmulator ? testFbConfig : firebaseConfig;

    // Initialize Firebase
    firebase.initializeApp(config);

    // Emulate Auth: https://firebase.google.com/docs/emulator-suite/connect_auth#web-namespaced-api
    if (shouldUseEmulator) {
      firebase.auth().useEmulator('http://localhost:9099/');
    }

    loginUserFromIdToken();

    const unsub = firebase.auth().onAuthStateChanged(firebaseUser => {
      if (firebaseUser) {
        const cleanedFirebaseUser = JSON.parse(JSON.stringify(firebaseUser));
        dispatch(setFirebaseUser(cleanedFirebaseUser));
        GA.Actions.session.userSignedIn();

        if (isSignUp(firebaseUser)) GA.Actions.session.userSignedUp();

        if (firebaseUser.emailVerified) {
          setUserInStore();
        } else {
          showMustVerifyEmailToast(firebaseUser);
          firebase.auth().signOut();
        }
      } else {
        reactQueryClient.invalidateQueries([queryKeys.session]);
        GA.Actions.session.userSignedOut();
      }
    });

    return unsub;
  }, []);
}
