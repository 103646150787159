import eventEmitter, { eventKeys } from 'shared/utils/event-emitter';
import { Annotation } from 'web/store/annotations/types';
import { userInteraction } from 'web/utils/analytics/google-analytics';

export const navigatedToAnnotationFromFeed = (annotation: Annotation) => {
  userInteraction('annotation_from_feed', {
    action: `navigated to annotation ${annotation.id}`,
    label: 'Navigated to annotation from feed',
    title: 'Reader',
  });
};

export const navigatedToCommentInReader = (commentId: string) => {
  userInteraction('comment_from_feed', {
    action: `navigated to comment ${commentId}`,
    label: 'Navigated to comment in Reader',
    title: 'Reader',
  });
};

export const clickedAnnotation = (annotation: Annotation) => {
  userInteraction('annotation_click_from_reader', {
    action: 'annotation click',
    label: 'Clicked annotation',
    title: `Annotation ${annotation.id}`,
  });
};

export const openedReaderDiscussionFeed = () => {
  userInteraction('discussion_feed_open', {
    action: 'opened discussion feed',
    label: 'Opened discussion feed in book',
    title: 'Reader',
  });
};

export const closedReaderDiscussionFeed = () => {
  userInteraction('discussion_feed_close', {
    action: 'closed discussion feed',
    label: 'Closed discussion feed in book',
    title: 'Reader',
  });
};

export const expandedAnnotation = (annotation: Annotation) => {
  userInteraction('annotation_expand', {
    action: 'expanded annotation',
    label: 'Expanded annotation',
    title: `Annotation ${annotation.id}`,
  });
};

export const likedCommentInDiscussionPanel = () => {
  userInteraction('liked_comment_in_side_panel', {
    action: 'liked comment in side panel',
    label: 'Liked comment in side panel',
    title: 'Reader',
  });
};

export const likedCommentInDiscussionBubble = () => {
  userInteraction('liked_comment_in_discussion_bubble', {
    action: 'liked comment in discussion bubble',
    label: 'Liked comment in discussion bubble',
    title: 'Reader',
  });
};

// a poc for how this would work as an observer rather than call GA events imperatively
eventEmitter.on(eventKeys.discussion.navigateToSection, (section: string) => {
  userInteraction('discussion_feed_navigation', {
    action: 'discussion feed navigation',
    label: 'Discussion feed navigation',
    title: `Discussion feed navigation to '${section}' section`,
  });
});
