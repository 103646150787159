import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Draft } from 'immer';
import { useSelector } from 'react-redux';
import { EpubDomSelection } from 'web/pages/Authenticated/Reader/utils/types';
import { PdfDomSelection } from 'web/pages/Authenticated/Reader/PDF/utils/types';
import { Annotation } from 'web/store/annotations/types';
import { RootState } from '..';

export type DomSelection = PdfDomSelection | EpubDomSelection;

export type MenuDomSelection = DomSelection & { portalNode: HTMLElement };

// This global state is being replaced by ReaderContext and is now in feature freeze
// New functionality should be added to the ReaderContext, and
// current functionality should be migrated from here to there over time
interface ReaderSliceState {
  focusedAnnotation: Annotation | null;
  /** menuDomSelection - Text selection for what is highlighted in the DOM.
   * NOTE: if the selection is a PDF, the `rects` property will reflect the
   * scaled PDF page size (NOT adjusted for the original PDF page size).
   */
  menuDomSelection: MenuDomSelection | null;
  scrollEnabled: boolean;
  /** domSelection - Selection for when the "Create Annotation" input is showing.
   * NOTE: if the selection is a PDF, the `rects` property will already be adjusted
   * for the original PDF page size.
   */
  domSelection: DomSelection | null;
  /** pdfScaleFactor - This reflects the value of the pdf.js CSS variable `--scale-factor`
   * https://github.com/mozilla/pdf.js/blob/3f7060e77743a2d3244c95d364b52e0331ed4ed2/web/pdf_viewer.css#L61
   */
  pdfScaleFactor: number;
}

const initialState: ReaderSliceState = {
  focusedAnnotation: null,
  menuDomSelection: null,
  scrollEnabled: true,
  domSelection: null,
  pdfScaleFactor: 1,
};

export const readerSlice = createSlice({
  name: 'reader',
  initialState,
  reducers: {
    setFocusedAnnotation: (state, action: PayloadAction<Annotation | null>) => {
      state.focusedAnnotation = action.payload;
    },
    setMenuDomSelection: (state, action: PayloadAction<MenuDomSelection | null>) => {
      state.menuDomSelection = action.payload as Draft<MenuDomSelection> | null;
    },
    setScrollEnabled: (state, action: PayloadAction<boolean>) => {
      state.scrollEnabled = action.payload;
    },
    setDomSelection: (state, action: PayloadAction<DomSelection | null>) => {
      /**
       * There is some weirdness with the `DomSelection` type here, specifically the `contents` property.
       * The `Contents` type from epubjs references some global types like `Window` and `Document`, which
       * seems to be causing some issues with Redux Toolkit/Immer. For now, we'll just cast the payload
       * to `Draft<DomSelection> | null` since that is what it expects and move on.
       */
      state.domSelection = action.payload as Draft<DomSelection> | null;
    },
    setPdfScaleFactor: (state, action: PayloadAction<number>) => {
      // Set the pdf.js CSS variable for the scale factor
      // https://github.com/mozilla/pdf.js/blob/3f7060e77743a2d3244c95d364b52e0331ed4ed2/web/pdf_viewer.css#L61
      document.documentElement.setAttribute('style', `--scale-factor: ${action.payload}`);

      state.pdfScaleFactor = action.payload;
    },
    clearReaderState: state => {
      state = initialState as Draft<ReaderSliceState>;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setFocusedAnnotation,
  setMenuDomSelection,
  setScrollEnabled,
  setDomSelection,
  setPdfScaleFactor,
  clearReaderState,
} = readerSlice.actions;

/**
 * Custom hook to access the reader slice of the Redux store.
 */
export const useReaderState = (): ReaderSliceState =>
  useSelector((state: RootState) => state.reader);

export default readerSlice.reducer;
