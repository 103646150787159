// key 'constants' used for local storage
// will help us
//   - avoid typos
//   - always use a key that exists
//   - avoid clobbering other keys
//   - clear local storage of unused keys if we need to

export const localStorageKeys = {
  settings: {
    colorModeStorageKey: 'colorModeStorageKey',
  },
  reader: {
    zoomLevelsByBookId: 'zoomLevelsByBookId',
  },
};
